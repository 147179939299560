<template>
  <div>
    <v-col cols="12" class="primary mt-4 mx-0 pa-6 white--text">
      <v-row>
        <div class="text-uppercase font-weight-bold">motion tracker</div>
      </v-row>
      <v-row class="">
        <div class="">
          Lorem ipsum dolor sit, amet consectetur adipisicing elit. Aliquid enim
          eius tempore natus! Neque dolore explicabo aliquid inventore
          consequuntur vitae illum ratione doloribus quo, cum in, soluta
          perferendis laboriosam qui.
        </div>
      </v-row>
    </v-col>

    <div class="pa-2">
      <div class="d-flex">
        <v-dialog width="auto">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" v-on="on" v-bind="attrs"
              ><v-icon left>mdi-plus</v-icon> new motion</v-btn
            >
          </template>

          <v-card width="500">
            <v-card-title>
              <p class="text-uppercase mx-auto">create new motion</p>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text class="mt-2">
              <v-form>
                <v-autocomplete
                  label="Author"
                  outlined
                  dense
                  v-model="author"
                ></v-autocomplete>

                <v-text-field
                  label="Title/Chapter"
                  outlined
                  dense
                  v-model="title"
                ></v-text-field>

                <v-menu
                  v-model="dateMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="date"
                      label="Notice Date"
                      readonly
                      outlined
                      dense
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="date"
                    @input="dateMenu = false"
                  ></v-date-picker>
                </v-menu>

                <v-textarea
                  label="Description"
                  outlined
                  dense
                  v-model="description"
                ></v-textarea>
                <v-btn color="primary" block> create </v-btn>
              </v-form>
            </v-card-text>
          </v-card>
        </v-dialog>
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>

        <v-text-field
          label="Search"
          outlined
          dense
          append-icon="mdi-magnify"
          v-model="search"
        ></v-text-field>
      </div>

      <v-data-table
        :search="search"
        :headers="headers"
        :items="items"
        class="text-capitalize"
      >
        <template v-slot:[`item.intro`]="{ item }">
          {{ item.intro | moment("Do MMMM YYYY") }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <div class="d-flex">
            <v-btn
              small
              rounded
              :color="item.passed ? 'success' : 'primary'"
              text
              outlined
              @click="makeBill(item)"
              >{{ item.passed ? "passed" : "bill" }}</v-btn
            >
            <v-spacer></v-spacer>
            <v-btn
              small
              rounded
              color="primary"
              text
              outlined
              @click="makeAct(item)"
              >act</v-btn
            >
          </div>
        </template>
      </v-data-table>

      <v-dialog width="auto" v-model="makeBillDialog">
        <v-card width="500">
          <v-card-title>
            <p class="text-uppercase mx-auto">mark motion as passed</p>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text class="mt-3">
            <v-text-field
              label="Ordinance No"
              v-model="ordinanceNumber"
              outlined
              dense
            ></v-text-field>
            <v-file-input
              label="Attach Document"
              outlined
              dense
              v-model="billDocument"
            ></v-file-input>

            <v-btn color="primary" block>pass motion</v-btn>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog width="auto" v-model="makeActDialog">
        <v-card width="500">
          <v-card-title>
            <p class="text-uppercase mx-auto">mark bill as passed</p>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text class="mt-3">
            <v-text-field
              label="Act Number"
              outlined
              dense
              v-model="actNumber"
            ></v-text-field>
            <v-file-input
              label="Attach Document"
              outlined
              dense
              v-model="actDocument"
            ></v-file-input>

            <v-btn color="primary" block>pass bill</v-btn>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      author: "",
      title: "",
      commitee: "",
      commiteeItems: [],
      description: "",
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      dateMenu: false,
      search: "",
      headers: [
        {
          text: "title/chapter",
          value: "title",
          class: "text-uppercase black--text",
        },
        {
          text: "author",
          value: "author",
          class: "text-uppercase black--text",
        },
        {
          text: "commitee",
          value: "commitee",
          class: "text-uppercase black--text",
        },
        {
          text: "introduction date",
          value: "intro",
          class: "text-uppercase black--text",
        },
        {
          text: "description",
          value: "description",
          width: "35%",
          class: "text-uppercase black--text",
        },
        {
          text: "actions",
          value: "actions",
          class: "text-uppercase black--text",
        },
      ],
      items: [
        {
          title: "budget",
          author: "david kimani",
          commitee: "POGO",
          intro: new Date(),
          description:
            "This is a description for the proposed budget motion which is a long string",
          passed: false,
        },
        {
          title: "budget",
          author: "david kimani",
          commitee: "POGO",
          intro: new Date(),
          description:
            "This is a description for the proposed budget motion which is a long string",
          passed: true,
        },
        {
          title: "budget",
          author: "david kimani",
          commitee: "POGO",
          intro: new Date(),
          description:
            "This is a description for the proposed budget motion which is a long string",
          passed: false,
        },
        {
          title: "budget",
          author: "david kimani",
          commitee: "POGO",
          intro: new Date(),
          description:
            "This is a description for the proposed budget motion which is a long string",
          passed: true,
        },
        {
          title: "budget",
          author: "david kimani",
          commitee: "POGO",
          intro: new Date(),
          description:
            "This is a description for the proposed budget motion which is a long string",
          passed: false,
        },
      ],
      billDocument: null,
      actDocument: null,
      makeBillDialog: false,
      makeActDialog: false,
      actNumber: "",
      ordinanceNumber: "",
    };
  },
  methods: {
    makeBill(item) {
      console.log(item);
      if (!item.passed) {
        this.makeBillDialog = true;
      }
    },
    makeAct(item) {
      console.log(item);
      if (!item.passed) {
        this.makeActDialog = true;
      }
    },
  },
};
</script>

<style></style>
